import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { RingBaseSelect } from '../../components/RingBaseSelect'
import { SEO } from '../../components/SEO'
import { Txt } from '../../components/Txt'

export const Head = () => (
    <SEO
        title="経験豊富なCADデザイナーによるデザイン画製作依頼は無料でご利用頂けます。 | 結婚指輪CAD-platinum"
        description="お好みの形状、材質に加え、おふたりのご趣味、出会い、思い出などをコンセプトにした世界に一つのデザイン画を、CADデザイナーが無料で製作致します。"
        page="designerorder"
    />
)

const DesignerorderPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'CADデザイナーによるフルオーダー' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/designer/designer-1.jpg"
                            alt="CADデザイナーによるデザイン画像製作"
                        />
                    </HeroImageWrapper>
                    <TopDescriptionWrapper>
                        <Txt size="l" sx={{ marginBottom: '32px' }}>
                            結婚指輪のオーダーメイド
                        </Txt>
                        <Txt size="l">お得なWEBオーダー価格</Txt>
                        <Txt
                            sx={{
                                marginBottom: '32px',
                                paddingBottom: '4px',
                                borderBottom: 'solid 3px #ffb6c1',
                            }}
                        >
                            ペア(2本) 88,000円~
                        </Txt>
                    </TopDescriptionWrapper>
                    <TitleWrapper>
                        <Txt size="l" sx={{ marginBottom: '12px' }}>
                            気軽に楽しめるオーダーメイド
                        </Txt>
                    </TitleWrapper>
                    <StaticImage
                        src="../../images/fullorder/designer/order-p1.jpg"
                        alt=""
                    />
                    <DescriptionWrapper>
                        <Txt sx={{ marginBottom: '16px' }}>
                            まずはWEBでお気軽に
                        </Txt>
                        <Txt sx={{ marginBottom: '16px' }}>
                            【スマホで簡単 デザインのカスタマイズ 0円】
                        </Txt>
                        <Txt sx={{ marginBottom: '16px' }}>
                            【デザイン画像製作依頼 0円】
                        </Txt>
                    </DescriptionWrapper>
                    <TitleWrapper>
                        <Txt size="l">ご利用の流れ</Txt>
                    </TitleWrapper>
                    <section>
                        <StepTitleWrapper>
                            <NumberImageWrapper>
                                <StaticImage
                                    src="../../images/number/01.jpg"
                                    alt=""
                                />
                            </NumberImageWrapper>
                            <Txt sx={{ padding: '8px' }}>
                                まずはスマホで簡単カスタマイズ
                            </Txt>
                        </StepTitleWrapper>
                        <LeftWrapper>
                            <InnerWrapper>
                                <StaticImage
                                    src="../../images/fullorder/designer/designer-4.jpg"
                                    alt=""
                                />
                            </InnerWrapper>
                        </LeftWrapper>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            イメージに近いリングの形状を選択し、材質、幅、厚み、石留めやミル打ち、艶消しなどお好みのカスタマイズを加えて、理想のデザインに近づけます。
                        </Txt>
                    </section>
                    <section>
                        <StepTitleWrapper>
                            <NumberImageWrapper>
                                <StaticImage
                                    src="../../images/number/02.jpg"
                                    alt=""
                                />
                            </NumberImageWrapper>
                            <Txt sx={{ padding: '8px' }}>
                                試着用サンプルリング無料配送
                            </Txt>
                        </StepTitleWrapper>
                        <RightWrapper>
                            <InnerWrapper>
                                <StaticImage
                                    src="../../images/fullorder/designer/order-p2.jpg"
                                    alt=""
                                />
                            </InnerWrapper>
                        </RightWrapper>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            イメージに近いリングにカスタマイズできたら、試着用リングの無料配送で、基本形状、幅や厚みのボリューム感、石留めの雰囲気などをチェック。スタッフおすすめリングやサイズ測定用ゲージも同封致します。
                        </Txt>
                    </section>
                    <section>
                        <StepTitleWrapper>
                            <NumberImageWrapper>
                                <StaticImage
                                    src="../../images/number/03.jpg"
                                    alt=""
                                />
                            </NumberImageWrapper>
                            <Txt sx={{ padding: '8px' }}>
                                オリジナルのデザイン画作成を依頼
                            </Txt>
                        </StepTitleWrapper>
                        <LeftWrapper>
                            <InnerWrapper>
                                <StaticImage
                                    src="../../images/fullorder/designer/designer-6.jpg"
                                    alt=""
                                />
                            </InnerWrapper>
                        </LeftWrapper>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            試着用リングを参考に、自分好みのリングにアレンジを加えたデザイン画像の作成をご依頼ください。実際にリングの造形データを写真化した画像なので、オリジナルのデザインでも仕上がりや、お見積もりを無料でご確認頂けます。（2本ペア価格帯の目安88,000~132,000円）
                        </Txt>
                    </section>
                    <section>
                        <StepTitleWrapper>
                            <NumberImageWrapper>
                                <StaticImage
                                    src="../../images/number/04.jpg"
                                    alt=""
                                />
                            </NumberImageWrapper>
                            <Txt sx={{ padding: '8px' }}>
                                ご来店でのご相談も承っております
                            </Txt>
                        </StepTitleWrapper>
                        <RightWrapper>
                            <InnerWrapper>
                                <StaticImage
                                    src="../../images/salon/salon1.jpg"
                                    alt=""
                                />
                            </InnerWrapper>
                        </RightWrapper>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            デザインのご相談は、東京表参道ショールームでも承っております。人気デザインのご試着や、サイズ測定、加工のご相談などお気軽にご利用下さい。
                        </Txt>
                    </section>
                    <TitleImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/full-5.jpg"
                            alt=""
                        />
                    </TitleImageWrapper>
                    <TitleWrapper>
                        <Txt size="l">ご注文の流れ</Txt>
                    </TitleWrapper>
                    <section>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            ご注文の場合はWEBサイト、または表参道ショールームで承っております。ご注文日から2~3週間でオリジナルリングが完成します。
                        </Txt>
                        <StaticImage
                            src="../../images/fullorder/full-6.jpg"
                            alt=""
                        />
                    </section>
                    <section>
                        <Txt
                            size="s"
                            sx={{ display: 'block', padding: '12px' }}
                        >
                            納品後もリングのサイズ調整や修理、アレンジなどアフターサービスも万全。遠方のお客様は表参道ショールームに郵送でも承っております。
                        </Txt>
                        <RightWrapper>
                            <InnerWrapper>
                                <StaticImage
                                    src="../../images/fullorder/designer/order-p3.jpg"
                                    alt=""
                                />
                            </InnerWrapper>
                        </RightWrapper>
                    </section>
                    <CatchWrapper>
                        <Txt size="l" sx={{ marginTop: '16px' }}>
                            結婚指輪のオーダーメイド
                        </Txt>
                        <Txt
                            size="l"
                            sx={{
                                marginTop: '16px',
                                paddingBottom: '4px',
                                borderBottom: 'solid 3px #ffb6c1',
                            }}
                        >
                            2本ペア価格 88,000円~
                        </Txt>
                        <Txt size="l" sx={{ marginTop: '16px' }}>
                            まずは気軽に
                        </Txt>
                        <Txt size="l" sx={{ marginTop: '16px' }}>
                            デザイン画製作依頼 0円
                        </Txt>
                    </CatchWrapper>
                    <Divider />
                    <RingBaseSelect hideCatchTxt marginTop="32px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const HeroImageWrapper = styled('h1')({
    margin: 0,
})
const TopDescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '56px 8px 24px',
})
const DescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 8px',
})
const TitleWrapper = styled('h2')({
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
})
const TitleImageWrapper = styled('div')({
    margin: '10px 0',
})
const StepTitleWrapper = styled('h3')({
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
})
const NumberImageWrapper = styled('div')({
    width: '60px',
})
const LeftWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})
const RightWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
})
const InnerWrapper = styled('div')({
    width: '80%',
})
const CatchWrapper = styled('div')({
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '8px',
})
const Divider = styled('hr')({
    margin: '8px 0',
})

export default DesignerorderPage
